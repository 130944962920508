<template>
  <div id="publication-list">
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <CollectionForm
        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Résiliation') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </CollectionForm>
    </v-dialog>
    <v-dialog
      v-model="isFormAttributionActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <AttributionForm
        v-model="item"
        :is-form-active="isFormAttributionActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Attribuer le dossier') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormAttributionActive = !isFormAttributionActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormAttributionActive = !isFormAttributionActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </AttributionForm>
    </v-dialog>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Résiliation") }}<v-chip
          outlined
          class="ml-2"
        >
          {{ totalItems }}
        </v-chip>
      </v-card-title>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        :mobile-breakpoint="250"
        hide-default-footer
        :show-select="false"
        dense
        fixed-header
        :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <template #item="options">
          <Row :options="options"
               :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Resiliation' }"
               @refetch-data="fetchItems"
               @saved="saved">
            <template #[`item.completion_date`]="{item}">
              <span v-if="item.completion_date && !item.completion_date.includes('0000')">{{ formatDateShort(item.completion_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
            </template>
            <!-- name -->
            <template #[`item.author.full_name`]="{item}">
              <div
                v-if="item.author && item.author.id"
                class="d-flex align-center"
              >
                <v-avatar
                  :color="item.author.avatar ? '' : 'primary'"
                  :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="30"
                >
                  <v-img
                    v-if="item.author.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.author.full_name) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3">
                  <router-link
                    :to="{ name : 'author-view', params : { id : item.author_id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.author.full_name }}
                  </router-link>
                  <!--              <small>@{{ item.publicationname }}</small>-->
                </div>
              </div>
            </template>
            <template #[`item.author.old_author`]="{item}">
              <v-icon
                v-if="item.author && item.author.old_author"
                small
                color="success"
              >
                {{ icons.mdiCheckCircle }}
              </v-icon>
            </template>
            <template #[`item.is_reading1`]="{item}">
              <span>
                <v-icon
                  v-if="item.is_reading"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </span>
            </template>
            <template #[`item.author.published`]="{item}">
              <div class="text-center">
                <v-icon
                  v-if="item.published"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
            <!-- pay -->
            <template #[`item.country`]="{item}">
              <div
                v-if="item.country"
                class="d-flex align-center"
              >
                <span class="text-capitalize">{{ item.country.name }}</span>
              </div>
            </template>

            <!-- status -->
            <template #[`item.huissier1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'huissier')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      style="cursor: pointer"
                      v-bind="attrs"
                      @click="openDialog({...item, action: 'paiement_huissier',activity_date: item.huissier,date_column:'huissier', 'activity_date_label': $t('Huissier'), info: $t('Confirmez-vous que l\'huissier a été fait ?') })"
                      v-on="on"
                    ><span v-if="item.huissier && !item.huissier.includes('0000')">{{ formatDateShort(item.huissier || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                  </template>
                  <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'paiement_huissier').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                  <template v-else>
                    <div>
                      <p
                        v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_huissier')"

                        :key="precord.id"
                      >
                        <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                        <span
                          v-if="precord.date_record"
                          class="mr-2"
                        >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                        <span class="mr-2">{{ precord.comment }}</span>
                      </p>
                    </div>
                  </template>
                </v-tooltip>

                <v-tooltip
                  v-if="!item.huissier || item.huissier.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'paiement_huissier', activity_date: item.huissier,date_column:'huissier', 'activity_date_label': $t('Huissier'), info: $t('Confirmez-vous que l\'huissier a été fait ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template #[`item.end_of_file1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'end_of_file')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer"
                        v-bind="attrs"
                        @click="openDialog({...item, action: 'paiement_end_of_file',activity_date: item.end_of_file,date_column:'end_of_file', 'activity_date_label': $t('Fin de dossier'), info: $t('Confirmez-vous que ce la fin de dossier?') })"
                        v-on="on"
                      ><span v-if="item.end_of_file && !item.end_of_file.includes('0000')">{{ formatDateShort(item.end_of_file || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'paiement_end_of_file').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_end_of_file')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.end_of_file || item.end_of_file.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'paiement_end_of_file', activity_date: item.end_of_file,date_column:'end_of_file', 'activity_date_label': $t('Fin de dossier'), info: $t('Confirmez-vous que ce la fin de dossier') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template #[`item.termination_bod1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'termination_bod')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer"
                        v-bind="attrs"
                        @click="openDialog({...item, action: 'resiliation_termination_bod',activity_date: item.termination_bod,date_column:'termination_bod', 'activity_date_label': $t('Résiliation BOD'), info: $t('Confirmez-vous que la résiliation BOD a été faite ?') })"
                        v-on="on"
                      ><span v-if="item.termination_bod && !item.termination_bod.includes('0000')">{{ formatDateShort(item.termination_bod || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'resiliation_termination_bod').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'resiliation_termination_bod')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.termination_bod || item.termination_bod.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'resiliation_termination_bod',activity_date: item.termination_bod,date_column:'termination_bod', 'activity_date_label': $t('Résiliation BOD'), info: $t('Confirmez-vous que la résiliation BOD a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.termination1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'termination')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer"
                        v-bind="attrs"
                        @click="openDialog({...item, action: 'resiliation_termination',activity_date: item.termination,date_column:'termination', 'activity_date_label': $t('Résiliation'), info: $t('Confirmez-vous que la résiliation a été faite ?') })"
                        v-on="on"
                      ><span v-if="item.termination && !item.termination.includes('0000')">{{ formatDateShort(item.termination || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'resiliation_termination').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'resiliation_termination')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.termination || item.termination.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'resiliation_termination',activity_date: item.termination,date_column:'termination', 'activity_date_label': $t('Résiliation'), info: $t('Confirmez-vous que la résiliation a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.termination_primento1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'termination_primento')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer"
                        v-bind="attrs"
                        @click="openDialog({...item, action: 'resiliation_termination_primento',activity_date: item.termination_primento,date_column:'termination_primento', 'activity_date_label': $t('Résiliation Primento'), info: $t('Confirmez-vous que la résiliation Primento a été faite ?') })"
                        v-on="on"
                      ><span v-if="item.termination_primento && !item.termination_primento.includes('0000')">{{ formatDateShort(item.termination_primento || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'resiliation_termination_primento').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'resiliation_termination_primento')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.termination_primento || item.termination_primento.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'resiliation_termination_primento',activity_date: item.termination_primento,date_column:'termination_primento', 'activity_date_label': $t('Résiliation Primento'), info: $t('Confirmez-vous que la résiliation Primento a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.author_mail1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'author_mail')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer"
                        v-bind="attrs"
                        @click="openDialog({...item, action: 'resiliation_author_mail',activity_date: item.author_mail,date_column:'author_mail', 'activity_date_label': $t('Mail auteur'), info: $t('Confirmez-vous que la résiliation Primento a été faite ?') })"
                        v-on="on"
                      ><span v-if="item.author_mail && !item.author_mail.includes('0000')">{{ formatDateShort(item.author_mail || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'resiliation_author_mail').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'resiliation_author_mail')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.author_mail || item.author_mail.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'resiliation_author_mail',activity_date: item.author_mail,date_column:'author_mail', 'activity_date_label': $t('Mail auteur'), info: $t('Confirmez-vous que le Mail auteur a été envoyé?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template #[`item.verification_deletion1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'verification_deletion')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer"
                        v-bind="attrs"
                        @click="openDialog({...item, action: 'resiliation_verification_deletion',activity_date: item.verification_deletion,date_column:'verification_deletion', 'activity_date_label': $t('Vérif suppression'), info: $t('Confirmez-vous que la vérif suppression a été faite ?') })"
                        v-on="on"
                      ><span v-if="item.verification_deletion && !item.verification_deletion.includes('0000')">{{ formatDateShort(item.verification_deletion || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'resiliation_verification_deletion').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'resiliation_verification_deletion')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.verification_deletion || item.verification_deletion.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'resiliation_verification_deletion',activity_date: item.verification_deletion,date_column:'verification_deletion', 'activity_date_label': $t('Vérif suppression'), info: $t('Confirmez-vous que la vérif suppression a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template #[`item.author_mail_d_151`]="{item}">
              <div :style="stateColor(item.publication_colors, 'author_mail_d_15')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer"
                        v-bind="attrs"
                        @click="openDialog({...item, action: 'resiliation_author_mail_d_15',activity_date: item.author_mail_d_15,date_column:'author_mail_d_15', 'activity_date_label': $t('Mail auteur J +15'), info: $t('Confirmez-vous que le Mail auteur J +15 a été envoyé?') })"
                        v-on="on"
                      ><span v-if="item.author_mail_d_15 && !item.author_mail_d_15.includes('0000')">{{ formatDateShort(item.author_mail_d_15 || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'resiliation_author_mail_d_15').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'resiliation_author_mail_d_15')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.author_mail_d_15 || item.author_mail_d_15.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'resiliation_author_mail_d_15',activity_date: item.author_mail_d_15,date_column:'author_mail_d_15', 'activity_date_label': $t('Mail auteur J +15'), info: $t('Confirmez-vous que le Mail auteur J +15 a été envoyé?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template #[`item.end_contract1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'end_contract')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                    ><span v-if="item.end_contract && !item.end_contract.includes('0000')">{{ formatDateShort(item.end_contract || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                  </template>
                  <template>
                    <div>
                      <p
                        v-for="precord in item.publication_records.filter(el=>el.type === 'resiliation_end_contract')"

                        :key="precord.id"
                      >
                        <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                        <span
                          v-if="precord.date_record"
                          class="mr-2"
                        >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                        <span class="mr-2">{{ precord.comment }}</span>
                      </p>
                    </div>
                  </template>
                </v-tooltip>
              </div>
            </template>
            <!--        <template #[`item.end_contract`]="{item}">
          <div :style="stateColor(item.publication_colors, 'end_contract')">
            <template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'resiliation_end_contract',activity_date: item.end_contract,date_column:'end_contract', 'activity_date_label': $t('Fin de contrat'), info: $t('Confirmez-vous de marquer comme fin de contrat ?') })"
                    v-on="on"
                  ><span v-if="item.end_contract && !item.end_contract.includes('0000')">{{ formatDateShort(item.end_contract || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                </template>
                <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'resiliation_end_contract').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                <template v-else>
                  <div>
                    <p
                      v-for="precord in item.publication_records.filter(el=>el.type === 'resiliation_end_contract')"

                      :key="precord.id"
                    >
                      <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                      <span
                        v-if="precord.date_record"
                        class="mr-2"
                      >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                      <span class="mr-2">{{ precord.comment }}</span>
                    </p>
                  </div>
                </template>
              </v-tooltip>
            </template>
            <v-tooltip
              v-if="!item.end_contract || item.end_contract.includes('0000')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog({...item, action: 'resiliation_end_contract',activity_date: item.end_contract,date_column:'end_contract', 'activity_date_label': $t('Fin de contrat'), info: $t('Confirmez-vous de marquer comme fin de contrat ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
            </v-tooltip>
          </div>
        </template>-->

            <!-- actions -->
            <template #[`item.actions`]="{item}">
              <!--          <v-btn
            color="primary"
            small
            icon
            @click="openDialogAttribution({...item, publication_id: item.id, gestion_id:null, assistant_id: null, task_date: null })"
          >
            <v-icon
              class="me-2"
              color="primary"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
          </v-btn>-->
            </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCalendar,
  mdiCheckboxMarkedCircleOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import itemStoreModule from './itemStoreModule'
import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import CollectionForm from '@/views/apps/publications/SignatureForm'
import AttributionForm from '@/views/apps/publications/AttributionForm'
import HeaderFilters from '@/components/HeaderFilters'
import ability from '@/plugins/acl/ability'
import TableRow from '@/components/TableRow'
import Row from "@/components/Row";

export default {
  components: {
    Row,
    TableRow,
    HeaderFilters,
    AttributionForm,
    CollectionForm,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      item,
      items,
      tableCommonColumns,
      resiliationTableColumns: tableColumns,
      filters,
      tabFilter,
      tableHead,
      publicationStates,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      selectedRows,
      isFormActive,
      isFormAttributionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      openDialogAttribution,
      deleteItem,
      isPublicationbod,
      deleteDialog,
      confirmDelete,
      fetchItems,tableHeight,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      showResiliation,
    } = controller()

    const superiors = ref([])
    const assistants = ref([])

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Publication', value: 'publication' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]
    store
      .dispatch('app-publication/fetchCountries', { per_page: 500 })
    store
      .dispatch('app-publication/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchGenres', { per_page: 500 })
    store
      .dispatch('app/fetchFormats', { per_page: 500 })
    store
      .dispatch('app-publication/fetchStatuses', { per_page: 500, status_type: 'publication' })

    /* stateFilter.value = [
      publicationStates.value.SIGNED_STATE,
      publicationStates.value.COVER_STATE,
      publicationStates.value.COLLECTED_STATE,
      publicationStates.value.TEXT_STATE,
      publicationStates.value.SUPERVISOR_STATE,
      publicationStates.value.PUBLICATION_STATE] */

    /* tabFilter.value = 'text' */

    filters.value = tableCommonColumns
    filters.value.push(...tableColumns.filter(ele => !ele.action || ability.can(ele.action, 'Resiliation')))
    showResiliation.value = true
    /* filters.value.push(
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ) */

    axios
      .get('/listWorkers', { params: { term: '', /* user: item.value.gestion_id, */ rowsPerPage: 100 } })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value
        }
      })

    const formatDateShort = (date, option) => formatDate(date, option)

    return {
      item,
      items,
      superiors,
      tableColumns,
      searchQuery,
      tabFilter,
      filters,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      assistants,
      publicationTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isFormAttributionActive,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      openDialogAttribution,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      formatDateShort,
      isPublicationbod,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      showResiliation,
      fetchItems,tableHeight,
      tableHead,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCalendar,
        mdiCheckboxMarkedCircleOutline,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
